import { useSelector } from 'react-redux';
import { RootState } from 'store';
import DoneBtn from './ButtonDiv/CreateBtn';
import { EditToolOptionsDiv, MainDiv, ToolBarWrapper, PanelToolWrap, FacetPanelWrap, CalculatebtnWrap } from './style';
import CalculateButton from './ButtonDiv/Facet/CalculateBtn';
import DrawBtn from './ButtonDiv/Facet/DrawBtn';
import EditToolOptions from './ButtonDiv/Facet/EditToolOptions';
import AddPanelButton from 'components/tool/Editor/ButtonDiv/Panel/AddPanelButton';
import MovePanelButton from 'components/tool/Editor/ButtonDiv/Panel/MovePanelButton';
import DeletePanelBtn from './ButtonDiv/Panel/DeletePanelBtn';
import UndoFacetButton from './ButtonDiv/Facet/UndoButton';
import UndoPanelButton from './ButtonDiv/Panel/UndoBtn';
import RedoPanelButton from './ButtonDiv/Panel/RedoBtn';
import CancelBtn from './ButtonDiv/Panel/CancelBtn';

export default function EditorToolBar() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { editModeEnabled, deletedRoofIndexes, drawModeEnabled, roofIndexes, deleteObstructionsMode } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { enableAddPanel, enablePanelMove, deleteIndividualPanelMode, 
		activePanelMode } = 	useSelector((state: RootState) => state.panelSlice.data);
	const hasUserMadeModificaiton = !!deletedRoofIndexes?.length || drawModeEnabled || deleteObstructionsMode;
	const hasPanelModeActive = enableAddPanel || enablePanelMove || deleteIndividualPanelMode;
	const showDoneBtnIfUserHasModified = editModeEnabled && (hasUserMadeModificaiton || hasPanelModeActive);
	const showCanelBtnInIndividualPanelFnMOde= editModeEnabled && (drawModeEnabled || deleteObstructionsMode || activePanelMode);
	const showCalculateButton = roofIndexes.length > 0 && !activePanelMode;
	
	if(showIrradiance){
		return null;
	}

	return (
		<MainDiv>
			<ToolBarWrapper className='toolbar-wrap'>
				<FacetPanelWrap className='toolbar-btn-wrap'>
					<span className='toolbar-label'>Facets:</span>
					<EditToolOptionsDiv>
						<DrawBtn />
						<EditToolOptions/>
						{drawModeEnabled && <UndoFacetButton/>}
					</EditToolOptionsDiv>
				</FacetPanelWrap>
				<PanelToolWrap className='toolbar-btn-wrap'>
					<span className='toolbar-label'>Panels:</span>
					<EditToolOptionsDiv>
						<AddPanelButton />
						<MovePanelButton />
						<DeletePanelBtn/>
						<UndoPanelButton/>
						<RedoPanelButton/>
					</EditToolOptionsDiv>
				</PanelToolWrap>
				<CalculatebtnWrap>
					{ showCalculateButton && <CalculateButton />}
					{ showDoneBtnIfUserHasModified && <DoneBtn /> }
					{ showCanelBtnInIndividualPanelFnMOde && <CancelBtn /> }
				</CalculatebtnWrap>
				
			</ToolBarWrapper>
		</MainDiv>
	);
}